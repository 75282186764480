import React from 'react'
import Loaderanimation from '../common/Loaderanimation'

import aboutImg from '../../assets/images/Rectangle 27.png'
import demo2 from '../../assets/images/demo2.png'
const Services = () => {

    return (
        <>
        <Loaderanimation title='Services'/>
            <section className='bg-dark page-banner animate-up text-light' >
                <h3 class="bg-text">services</h3>
                <div className="vectors">
                    <div className='bg-vector ip-bvc1 p-0'></div>
                    <div className='bg-vector ip-bvc2 p-0'></div>
                    <div className='bg-vector ip-bvc3 p-0'></div>
                    <div className='bg-vector ip-bvc4 p-0'></div>
                    <div className='bg-vector ip-bvc5 p-0'></div>
                </div>
                <div className="container page-heading">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2 className='inner'>Take your brand to new heights.</h2>
                            <p className='mt-3'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Commodi doloremque nulla sequi omnis nobis itaque cumque fugiat officia perspiciatis tempora nihil veniam, architecto fugit minima minus impedit odit quibusdam nam, et sint earum? Beatae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime iure reiciendis quibusdam ducimus nostrum autem unde recusandae animi minus ea, repellendus sequi eos. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit, error! Eaque unde, at eveniet ipsam molestias aliquam quae a suscipit perspiciatis voluptatibus, repellendus consequatur! Officia nesciunt recusandae   laudantium.</p>
                            <p className='mt-3'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Commodi doloremque nulla sequi omnis nobis itaque cumque fugiat officia perspiciatis tempora nihil veniam, architecto fugit minima minus impedit odit quibusdam nam, et sint earum? Beatae. Lorem ipsum sit amet consectetur adipisicing elit. Maxime iure reiciendis </p>
                        </div>
                        <div className="col-12 col-md-6 about-img">
                            <img src={aboutImg} alt="" className='w-100' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-secondary pb-120 websites'>
                <div className="container">
                    <h3 className="text-md-light">websites</h3>
                    <div className="row">
                        <div className="col-12 col-md-6 pe-4">
                            <h2 className='inner '>User-centric websites that will engage & convert.</h2>
                            <p className='pt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                        </div>
                        <div className="col-12 col-md-6 text-end pt-5 mt-5 pm-0">
                            <img src={demo2} alt="" className='w-100' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-dark pb-120 text-light dig-mkt'>
                <div className="container">
                    <h3 className="text-md-dark">digital marketing</h3>
                    <div className="row">
                        <div className="col-12 col-md-6 text-end pt-5 mt-5 pm-0">
                            <img src={demo2} alt="" className='w-100' />
                        </div>
                        <div className="col-12 col-md-6 ps-4">
                            <h2 className='inner '>Elevating your brand through impactful digital marketing strategies.</h2>
                            <p className='pt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-secondary pb-120 branding'>
                <div className="container">
                    <h3 className="text-md-light">branding</h3>
                    <div className="row">
                        <div className="col-12 col-md-6 pe-4">
                            <h2 className='inner '>Creating and defining authentic brands.</h2>
                            <p className='pt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati eveniet itaque voluptatem asperiores tempore ad veritatis, reprehenderit nemo rem, nam aut, nostrum excepturi nihil modi sapiente quaerat maiores quae doloremque!</p>
                        </div>
                        <div className="col-12 col-md-6 text-end pt-5 mt-5 pm-0">
                            <img src={demo2} alt="" className='w-100' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services
